import React, { useState } from 'react';

const AuthComponent = (props: any) => {
  const [hide, setHide] = useState(true);
  return (
    <div className="fixed w-screen h-screen top-0 left-0 z-5 bg-white flex items-center justify-center">
      <form className="w-[400px] border-2 p-5" onSubmit={props.submitForm}>
        <h2 className="font-semibold text-2xl text-center">Login</h2>

        <div className="mt-10 flex flex-col gap-5">
          <input
            placeholder="Username"
            name="username"
            className="w-full"
            value={props.username}
            required
            onChange={props.formChange}
          />
          <div className="w-full relative justify-center align-middle">
            <input
              placeholder="Password"
              name="password"
              type={hide ? 'password' : 'text'}
              className="w-full"
              value={props.password}
              required
              onChange={props.formChange}
            />
            <div
              className="absolute right-2 top-2 cursor-pointer"
              onClick={() => setHide(!hide)}
            >
              {hide ? 'Show' : 'Hide'}
            </div>
          </div>
        </div>

        <button className="px-4 py-2 bg-black text-white w-full mt-10">
          Sign In
        </button>
      </form>
    </div>
  );
};

export default AuthComponent;
