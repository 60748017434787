import React, { useEffect } from 'react';
import axios from 'axios';
import * as xlsx from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useLocalStorage } from './utils/useLocalStorage';
import AuthComponent from './components/AuthComponent';
import { SERVER } from './config/axios';
import { handleAxiosError } from './utils/error';

function App() {
  const [accounts, setAccounts] = React.useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = React.useState(20);
  const totalPageCount = Math.ceil(accounts?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [drop, setDrop] = React.useState<any>(null);
  const [sortVal, setSortVal] = React.useState('ALL');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [modal, setModal] = React.useState(false);
  const [loggedIn, setLoggedIn]: any = useLocalStorage('token', '');
  const [formData, setFormData] = React.useState({
    username: '',
    password: '',
  });
  const [modal2, setModal2] = React.useState(false);

  const [arrow, setArrow] = React.useState(0);

  useEffect(() => {
    checkAuth();
  }, []);

  function checkAuth() {
    if (loggedIn) {
      setModal2(false);
      fetchAccounts();
    } else {
      setModal2(true);
    }
  }

  async function setAuth(e: any) {
    e.preventDefault();
    setLoading(true);

    SERVER.post('/auth/login', formData)
      .then((res: any) => {
        const { status, data } = res;

        if (status === 200) {
          localStorage.setItem('token', data.token);
          setLoggedIn(data.token);
          setModal2(false);
          toast.success('Logged In Successfully');
          setFormData({
            username: '',
            password: '',
          });
          fetchAccounts();
        }
      })
      .catch((err) => {
        handleAxiosError(err);
      })
      .finally(() => setLoading(false));
  }

  function clearAuth() {
    setLoading(true);
    setTimeout(() => {
      setLoggedIn('');
      setModal2(true);
      setLoading(false);
      toast.success('Logged Out Successfully');
      setAccounts([]);
    }, 1000);
  }

  const fetchAccounts = () => {
    setLoading(true);
    SERVER.get('/users')
      .then((res: any) => setAccounts(res.data.data.users.reverse()))
      .catch((err) => {
        handleAxiosError(err);
      })
      .finally(() => setLoading(false));
  };

  function sortToggle() {
    setAccounts([...accounts].reverse());
    arrow === 0 ? setArrow(1) : setArrow(0);
  }

  function sort() {
    if (sortVal !== 'ALL') {
      return accounts?.filter(
        (account: any) => account.status === sortVal.toLowerCase(),
      );
    }
    return accounts;
  }

  const filteredSearch = sort()?.filter((el: any) => {
    return (
      el?.accountNumber?.includes(searchTerm) ||
      el?.bvn?.includes(searchTerm) ||
      el.nin.includes(searchTerm)
    );
  });

  const allAccounts = filteredSearch;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allAccounts.slice(indexOfFirstItem, indexOfLastItem);

  // function allAccounts {
  //     return accounts.includes(searchTerm);
  // }

  const confirmedAccounts = accounts
    ?.filter((account: any) => account.status === 'confirmed')
    .reverse();
  const unconfirmedAccounts = accounts
    ?.filter((account: any) => account.status === 'pending')
    .reverse();

  const declinedAccounts = accounts
    ?.filter((account: any) => account.status === 'declined')
    .reverse();

  function exportResult(data: any, type: string) {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    xlsx.writeFile(workbook, `${type + '-' + Date.now()}.xlsx`);
  }

  const exportAll = () => {
    exportResult(allAccounts, 'AllAccounts');
    fetchAccounts();
  };
  const exportfiltered = () => {
    exportResult(filteredSearch, 'filteredAccounts');
    fetchAccounts();
  };
  const exportDeclined = () => {
    exportResult(declinedAccounts, 'DeclinedAccounts');
    fetchAccounts();
  };

  const exportConfirmed = () => {
    exportResult(confirmedAccounts, 'ConfirmedAccounts');
    fetchAccounts();
  };
  const exportUnconfirmed = () => {
    exportResult(unconfirmedAccounts, 'UnconfirmedAccounts');
    fetchAccounts();
  };
  const paginate = (val: string) => {
    if (val === 'next') {
      if (currentPage < totalPageCount) {
        setLoading(true);
        setTimeout(() => {
          setCurrentPage((currentPage) => currentPage + 1);
          setLoading(false);
        }, 1000);
      }
    } else {
      if (currentPage > 1) {
        setLoading(true);
        setTimeout(() => {
          setCurrentPage((currentPage) => currentPage - 1);
          setLoading(false);
        }, 1000);
      }
    }
  };

  const confirmAccount = (id: number) => {
    setLoading(true);
    SERVER.patch(`/users/${id}`, {
      status: 'confirmed',
    })
      .then((res: any) => toast.success('Account Confirmed Successfully'))
      .catch((err) => {
        handleAxiosError(err);
      })
      .finally(() => {
        setLoading(false);
        setDrop(null);
        fetchAccounts();
      });
  };

  const declineAccount = (id: number) => {
    setLoading(true);
    SERVER.patch(`/users/${id}`, {
      status: 'declined',
    })
      .then((res: any) => toast.success('Account Declined Successfully'))
      .catch((err) => {
        handleAxiosError(err);
      })
      .finally(() => {
        setLoading(false);
        setDrop(null);
        fetchAccounts();
      });
  };

  const deleteDeclined = () => {
    setLoading(true);
    SERVER.delete('/users')
      .then((res: any) => {
        console.log(res);
        toast.success('Declined Accounts Deletion Successful');
        setModal(false);
      })
      .catch((err) => {
        handleAxiosError(err);
      })
      .finally(() => {
        setLoading(false);
        fetchAccounts();
      });
  };

  const closeDrop = () => {
    setDrop(null);
  };

  const formChange = (e: any) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="container mx-auto">
      <div className="my-10 flex items-center justify-center gap-x-[500px]">
        <h2 className="text-3xl text-center font-bold">Customers</h2>

        <button
          className="px-4 py-2 bg-red-500 hover:bg-red-500/80 text-white"
          onClick={clearAuth}
        >
          Log Out
        </button>
      </div>
      <div className="w-fit mx-auto max-w-6xl">
        <div className="grid grid-cols-4 gap-x-4 my-5 h-28">
          <div
            className="border bg-black text-white flex flex-col items-center justify-center hover:cursor-pointer hover:bg-black/80"
            onClick={() => {
              setSortVal('ALL');
              sort();
            }}
          >
            <h2 className="text-lg">ALL</h2>
            <h2 className="font-bold text-4xl">{accounts?.length}</h2>
          </div>
          <div
            className="border bg-blue-500 text-white flex flex-col items-center justify-center hover:cursor-pointer hover:bg-blue-500/80"
            onClick={() => {
              setSortVal('PENDING');
              sort();
            }}
          >
            <h2 className="text-lg">PENDING</h2>
            <h2 className="font-bold text-4xl">
              {unconfirmedAccounts?.length}
            </h2>
          </div>
          <div
            className="border bg-green-500 text-white flex flex-col items-center justify-center hover:cursor-pointer hover:bg-green-500/80"
            onClick={() => {
              setSortVal('CONFIRMED');
              sort();
            }}
          >
            <h2 className="text-lg">CONFIRMED</h2>
            <h2 className="font-bold text-4xl">{confirmedAccounts?.length}</h2>
          </div>
          <div
            className="border bg-red-500 text-white flex flex-col items-center justify-center hover:cursor-pointer hover:bg-red-500/80"
            onClick={() => {
              setSortVal('DECLINED');
              sort();
            }}
          >
            <h2 className="text-lg">DECLINED</h2>
            <h2 className="font-bold text-4xl">{declinedAccounts?.length}</h2>
          </div>
        </div>
        <div className="flex justify-end gap-x-4 my-5">
          <div>
            <select
              onChange={(e) => setSortVal(e.target.value)}
              className="w-40"
              value={sortVal}
            >
              <option value="" disabled>
                Filter records by
              </option>
              {['ALL', 'PENDING', 'CONFIRMED', 'DECLINED'].map(
                (el: any, index: number) => (
                  <option value={el}>{el}</option>
                ),
              )}
            </select>
          </div>
          <div className="grow flex items-center">
            <input
              className="w-full"
              type="search"
              value={searchTerm}
              placeholder="Search by Account Number, BVN or NIN"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div>
            <button
              onClick={exportfiltered}
              disabled={searchTerm.length > 0 ? false : true}
              className="px-2 h-full bg-yellow-500 text-sm hover:bg-yellow-500/80 text-white rounded flex items-center gap-2 disabled:hover:bg-yellow-500 disabled:bg-yellow-500 disabled:opacity-50"
            >
              Export Filtered
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div>
            <select
              onChange={(e: any) => setItemsPerPage(e.target.value)}
              value={itemsPerPage}
              className="w-24"
            >
              <option value="" disabled>
                Records to display
              </option>
              {[20, 40, 60, 80, 100].map((el: any, index: number) => (
                <option value={el}>{el}</option>
              ))}
            </select>
          </div>

          <div
            className="flex items-center justify-center border border-black px-2 hover:cursor-pointer gap-1"
            onClick={sortToggle}
          >
            {arrow === 0 ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                  />
                </svg>
                <p>New - Old</p>
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                  />
                </svg>

                <p>Old - New</p>
              </>
            )}
          </div>
        </div>
        <div className="flex justify-center w-[1280px] gap-x-4 my-5">
          <button
            className="px-2 py-1 bg-green-500 text-sm hover:bg-green-500/80 text-sm/80 text-white rounded flex items-center gap-2"
            onClick={exportConfirmed}
          >
            Export Confirmed
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <button
            className="px-2 py-1 bg-blue-500 text-sm hover:bg-blue-500/80 text-sm/80 text-white rounded flex items-center gap-2"
            onClick={exportUnconfirmed}
          >
            Export Unconfirmed
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <button
            className="px-2 py-1 bg-orange-500 text-sm hover:bg-orange-500/80 text-sm/80 text-white rounded flex items-center gap-2"
            onClick={exportDeclined}
          >
            Export Declined
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <button
            onClick={exportAll}
            className="px-2 py-1 bg-gray-500 text-sm hover:bg-gray-500/80 text-white rounded flex items-center gap-2"
          >
            Export All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <button
            className="px-2 py-1 bg-red-500 text-sm hover:bg-red-500/80 text-sm/80 text-white rounded flex items-center gap-2"
            onClick={() => setModal(true)}
          >
            Delete Declined
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </button>
        </div>
        {modal && (
          <div className="w-screen h-screen fixed bg-black/80 top-0 left-0 z-50 flex items-center justify-center">
            <div className="w-96 h-72 bg-white p-10 text-center flex flex-col justify-between">
              <h2 className="font-semibold text-xl">
                Are you sure you want to delete{' '}
                <span className="text-red-500 text-2xl">
                  {declinedAccounts.length} Declined Accounts
                </span>
              </h2>

              <div className="flex justify-end gap-x-10">
                <button
                  onClick={() => setModal(false)}
                  className="px-4 py-2 border w-20 justify-center border-blue-500 hover:bg-blue-500/10 text-blue-500 rounded flex items-center gap-2"
                >
                  No
                </button>
                <button
                  onClick={deleteDeclined}
                  className="px-4 py-2 bg-blue-500 w-20 justify-center hover:bg-blue-500/80 text-white rounded flex items-center gap-2"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="bg-gray-200 mx-auto min-h-[600px] w-[1280px] flex flex-col justify-between pb-3 mb-20">
          <table className="table-auto w-full" cellPadding={20}>
            <thead>
              <tr>
                <th>No.</th>
                <th>Account Number</th>
                <th>BVN</th>
                <th>NIN</th>
                <th>Residential Address</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems?.map((item: any, index: number) => (
                  <tr
                    className={`${
                      item.status === 'confirmed'
                        ? 'text-green-500'
                        : item.status === 'declined' && 'text-red-500'
                    }`}
                    key={index}
                  >
                    <td className="pl-10">{index + 1}</td>
                    <td className="pl-10">{item?.accountNumber}</td>
                    <td className="pl-10">{item?.bvn}</td>
                    <td className="pl-10">{item?.nin || 'NAN'}</td>
                    <td className="w-64 text-center">
                      {item?.residentialAddress || 'NAN'}
                    </td>
                    <td className="pl-10">{item?.status}</td>
                    <td>
                      <div className="relative" onMouseLeave={closeDrop}>
                        <button
                          className="px-2 py-1 text-sm bg-blue-500 hover:bg-blue-500/80 text-white rounded flex items-center gap-x-2"
                          onClick={() => setDrop(item.id)}
                        >
                          Select Action
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </button>
                        {drop === item.id && (
                          <div className="flex flex-col gap-y-3 absolute top-0 bg-white p-3 w-full z-10">
                            <p
                              className="hover:underline hover:cursor-pointer text-green-500"
                              onClick={() => confirmAccount(item.id)}
                            >
                              Confirm
                            </p>
                            <p
                              className="hover:underline hover:cursor-pointer text-red-500"
                              onClick={() => declineAccount(item.id)}
                            >
                              Decline
                            </p>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="w-full flex items-center justify-between px-4 sm:px-10 border-t border-gray-500 pt-2">
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700 flex gap-x-1">
                  Showing
                  <span className="font-medium">
                    {currentPage * itemsPerPage - itemsPerPage + 1}
                  </span>
                  to
                  <span className="font-medium">
                    {itemsPerPage * currentPage}
                  </span>
                  of
                  <span className="font-medium">{allAccounts.length}</span>
                  {allAccounts.length === 1 ? 'account' : 'accounts'}
                </p>
              </div>
              <div className="flex gap-x-5">
                <span
                  className={`relative cursor-pointer inline-flex items-center rounded-md border bg-gray-800 hover:bg-gray-800/80 text-gray-100 px-4 py-2 text-sm font-medium ${
                    currentPage === 1 &&
                    'bg-primary/60 hover:bg-primary/60 cursor-not-allowed'
                  }`}
                  onClick={() => paginate('prev')}
                >
                  Previous
                </span>
                <span
                  className={`relative cursor-pointer inline-flex items-center rounded-md border bg-gray-800 hover:bg-gray-800/80 text-gray-100 px-4 py-2 text-sm font-medium ${
                    totalPageCount === currentPage &&
                    'bg-primary/60 hover:bg-primary/60 cursor-not-allowed'
                  }`}
                  onClick={() => paginate('next')}
                >
                  Next
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!loggedIn && modal2 && (
        <AuthComponent
          submitForm={setAuth}
          username={formData.username}
          password={formData.password}
          formChange={formChange}
        />
      )}

      {loading && (
        <div className="fixed top-0 left-0 w-screen h-screen z-10 bg-black/80 grid place-items-center">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default App;
