import axios from 'axios';

export const SERVER = axios.create({
  baseURL: 'https://api.sofri-verify.sofriwebservices.com/api/v1',
});

SERVER.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.authorization = `Bearer ${JSON.parse(token)}`;
      config.headers.Accept = 'application/json';
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
