import { toast } from 'react-toastify';

export const handleAxiosError = async (error: any): Promise<void> => {
  if (error.response?.status === 401) {
    toast.info('Session Expired, Please log back in');
    localStorage.clear();
    return;
  }

  const errors = [error?.response?.data?.message];

  errors.flat().forEach((message) => {
    toast.error(message);
  });
};
